import { SocketClientTagGroupsSubscribeBody, SocketClientTagsSubscribeBody, Tag, TagGroup, TagGroupPartial, TagGroupRequired, TagGroupsService, TagPartial, TagRequired, TagsService } from '@icepanel/platform-api-client'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import getFirestoreId from '@/helpers/firestore-id'
import randomId from '@/helpers/random-id'
import Status from '@/helpers/status'
import { ServerEvents, Socket } from '@/plugins/socket'

export interface ITagModule {
  tagsCurrent: Record<string, Tag>
  tagsCache: Record<string, Tag> | null
  tagsCommit: Record<string, number>

  tagGroupsCurrent: Record<string, TagGroup>
  tagGroupsCache: Record<string, TagGroup> | null
  tagGroupsCommit: Record<string, number>

  tagsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>
  tagGroupsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>
}

const name = 'tag'

@Module({
  name,
  namespaced: true
})
export class TagModule extends VuexModule implements ITagModule {
  static namespace = name

  tagsCurrent: Record<string, Tag> = {}
  tagsCache: Record<string, Tag> | null = null
  tagsCommit: Record<string, number> = {}

  tagGroupsCurrent: Record<string, TagGroup> = {}
  tagGroupsCache: Record<string, TagGroup> | null = null
  tagGroupsCommit: Record<string, number>= {}

  tagsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>()
  tagGroupsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>()

  get generateTagId () {
    return () => getFirestoreId('tag')
  }

  get generateTagGroupId () {
    return () => getFirestoreId('tag-group')
  }

  get generateTag () {
    return (landscapeId: string, versionId: string, props: TagRequired, id = this.generateTagId()): { tag: Tag, tagUpsert: TagRequired & TagPartial } => {
      const commit = typeof this.tagsCommit[id] === 'number' ? this.tagsCommit[id] + 1 : props.commit || 0
      const defaultHandleId = randomId()
      return {
        tag: {
          handleId: defaultHandleId,
          labels: {},
          ...props,
          commit,
          createdAt: new Date().toISOString(),
          createdBy: 'user',
          createdById: this.context.rootState.user.user?.id || '',
          id,
          landscapeId,
          modelConnectionIds: [],
          modelObjectIds: [],
          updatedAt: new Date().toISOString(),
          updatedBy: 'user',
          updatedById: this.context.rootState.user.user?.id || '',
          version: -1,
          versionId
        },
        tagUpsert: {
          handleId: defaultHandleId,
          labels: {},
          ...props,
          commit
        }
      }
    }
  }

  get generateTagGroup () {
    return (landscapeId: string, versionId: string, props: TagGroupRequired, id = this.generateTagGroupId()): { tagGroup: TagGroup, tagGroupUpsert: TagGroupRequired & TagGroupPartial } => {
      const commit = typeof this.tagGroupsCommit[id] === 'number' ? this.tagGroupsCommit[id] + 1 : props.commit || 0
      const defaultHandleId = randomId()
      return {
        tagGroup: {
          handleId: defaultHandleId,
          labels: {},
          ...props,
          commit,
          createdAt: new Date().toISOString(),
          createdBy: 'user',
          createdById: this.context.rootState.user.user?.id || '',
          id,
          landscapeId,
          updatedAt: new Date().toISOString(),
          updatedBy: 'user',
          updatedById: this.context.rootState.user.user?.id || '',
          version: -1,
          versionId
        },
        tagGroupUpsert: {
          handleId: defaultHandleId,
          labels: {},
          ...props,
          commit
        }
      }
    }
  }

  get generateTagCommit () {
    return (id: string, props: Omit<TagPartial, 'commit'>): { tag: Tag, tagUpdate: TagPartial } => {
      const tag = structuredClone(this.tags[id])
      if (tag) {
        const commit = tag.commit + 1
        tag.updatedAt = new Date().toISOString()
        tag.updatedBy = 'user'
        tag.updatedById = this.context.rootState.user.user?.id || ''
        Object.assign(tag, props)
        return {
          tag: {
            ...tag,
            ...props,
            commit
          },
          tagUpdate: {
            ...props,
            commit
          }
        }
      } else {
        throw new Error(`Could not find tag ${id}`)
      }
    }
  }

  get generateTagGroupCommit () {
    return (id: string, props: Omit<TagGroupPartial, 'commit'>): { tagGroup: TagGroup, tagGroupUpdate: TagGroupPartial } => {
      const tagGroup = structuredClone(this.tagGroups[id])
      if (tagGroup) {
        const commit = tagGroup.commit + 1
        tagGroup.updatedAt = new Date().toISOString()
        tagGroup.updatedBy = 'user'
        tagGroup.updatedById = this.context.rootState.user.user?.id || ''
        return {
          tagGroup: {
            ...tagGroup,
            ...props,
            commit
          },
          tagGroupUpdate: {
            ...props,
            commit
          }
        }
      } else {
        throw new Error(`Could not find tag group ${id}`)
      }
    }
  }

  get tags () {
    return this.tagsCache || this.tagsCurrent
  }

  get tagGroups () {
    return this.tagGroupsCache || this.tagGroupsCurrent
  }

  get socket (): Socket {
    return this.context.rootState.socket.socket
  }

  @Mutation
  setTags (tags: Tag[] | Record<string, Tag>) {
    this.tagsCurrent = Object
      .values(tags)
      .reduce<ITagModule['tagsCurrent']>((p, c) => ({
        ...p,
        [c.id]: c
      }), {})
    this.tagsCommit = Object.values(this.tagsCurrent).reduce<ITagModule['tagsCommit']>((p, c) => ({
      ...p,
      [c.id]: c.commit
    }), {})
  }

  @Mutation
  setTagsCache (tagsCache: Record<string, Tag> | null) {
    this.tagsCache = tagsCache
  }

  @Mutation
  setTag (tag: Tag) {
    Vue.set(this.tagsCurrent, tag.id, tag)
    Vue.set(this.tagsCommit, tag.id, tag.commit)
  }

  @Mutation
  setTagVersion (tag: Tag) {
    if (
      this.tagsCommit[tag.id] === undefined ||
      tag.commit > this.tagsCommit[tag.id] ||
      (tag.commit === this.tagsCommit[tag.id] && this.tagsCurrent[tag.id] && tag.version > this.tagsCurrent[tag.id].version)
    ) {
      Vue.set(this.tagsCurrent, tag.id, tag)
      Vue.set(this.tagsCommit, tag.id, tag.commit)
    }
  }

  @Mutation
  removeTag (tag: string | Pick<Tag, 'id' | 'commit'>) {
    if (typeof tag === 'string') {
      Vue.delete(this.tagsCurrent, tag)
    } else if (tag.commit >= this.tagsCommit[tag.id]) {
      Vue.delete(this.tagsCurrent, tag.id)
      this.tagsCommit[tag.id] = tag.commit
    }
  }

  @Mutation
  setTagGroups (tagGroups: TagGroup[] | Record<string, TagGroup>) {
    this.tagGroupsCurrent = Object
      .values(tagGroups)
      .reduce<ITagModule['tagGroupsCurrent']>((p, c) => ({
        ...p,
        [c.id]: c
      }), {})
    this.tagGroupsCommit = Object.values(this.tagGroupsCurrent).reduce<ITagModule['tagGroupsCommit']>((p, c) => ({
      ...p,
      [c.id]: c.commit
    }), {})
  }

  @Mutation
  setTagGroupsCache (tagGroupsCache: Record<string, TagGroup> | null) {
    this.tagGroupsCache = tagGroupsCache
  }

  @Mutation
  setTagGroup (tagGroup: TagGroup) {
    Vue.set(this.tagGroupsCurrent, tagGroup.id, tagGroup)
    Vue.set(this.tagGroupsCommit, tagGroup.id, tagGroup.commit)
  }

  @Mutation
  setTagGroupVersion (tagGroup: TagGroup) {
    if (
      this.tagGroupsCommit[tagGroup.id] === undefined ||
      tagGroup.commit > this.tagGroupsCommit[tagGroup.id] ||
      (tagGroup.commit === this.tagGroupsCommit[tagGroup.id] && this.tagGroupsCurrent[tagGroup.id] && tagGroup.version > this.tagGroupsCurrent[tagGroup.id].version)
    ) {
      Vue.set(this.tagGroupsCurrent, tagGroup.id, tagGroup)
      Vue.set(this.tagGroupsCommit, tagGroup.id, tagGroup.commit)
    }
  }

  @Mutation
  removeTagGroup (tagGroup: string | Pick<TagGroup, 'id' | 'commit'>) {
    if (typeof tagGroup === 'string') {
      Vue.delete(this.tagGroupsCurrent, tagGroup)
    } else if (tagGroup.commit >= this.tagGroupsCommit[tagGroup.id]) {
      Vue.delete(this.tagGroupsCurrent, tagGroup.id)
      this.tagGroupsCommit[tagGroup.id] = tagGroup.commit
    }
  }

  @Mutation
  setTagsSubscriptionStatus (...params: Parameters<typeof this.tagsSubscriptionStatus.set>) {
    this.tagsSubscriptionStatus.set(...params)
  }

  @Mutation
  setTagGroupsSubscriptionStatus (...params: Parameters<typeof this.tagGroupsSubscriptionStatus.set>) {
    this.tagGroupsSubscriptionStatus.set(...params)
  }

  @Mutation
  tagsUnsubscribe () {
    this.tagsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.tagsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.tagsSubscriptionStatus.set(Status.idle())
  }

  @Mutation
  tagGroupsUnsubscribe () {
    this.tagGroupsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.tagGroupsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.tagGroupsSubscriptionStatus.set(Status.idle())
  }

  @Action({ rawError: true })
  async tagsSubscribe (body: SocketClientTagsSubscribeBody & { reconnect: boolean }) {
    this.tagsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.tagsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setTagsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect,
      versionId: body.versionId
    }))

    const initialValue: Tag[] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('tags-subscribe', {
        landscapeId: body.landscapeId,
        versionId: body.versionId
      }, (err, reply) => {
        if (reply) {
          const tagInitialValueListener: ServerEvents['tag-initial-value'] = ({ tags, finalValue, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...tags)

              if (finalValue) {
                this.context.commit('setTags', initialValue)

                this.context.commit('setTagsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe,
                  versionId: body.versionId
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const tagAddedListener: ServerEvents['tag-added'] = ({ tag, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setTagVersion', tag)
            }
            if (tag.createdById && tag.createdById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const tagModifiedListener: ServerEvents['tag-modified'] = ({ tag, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setTagVersion', tag)
            }
            if (tag.updatedById && tag.updatedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const tagRemovedListener: ServerEvents['tag-removed'] = ({ tag, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeTag', tag)
            }
            if (tag.deletedById && tag.deletedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('tag-initial-value', tagInitialValueListener)
            this.socket.off('tag-added', tagAddedListener)
            this.socket.off('tag-modified', tagModifiedListener)
            this.socket.off('tag-removed', tagRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('tags-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('tags unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('tag-initial-value', tagInitialValueListener)
          this.socket.on('tag-added', tagAddedListener)
          this.socket.on('tag-modified', tagModifiedListener)
          this.socket.on('tag-removed', tagRemovedListener)

          this.context.commit('setTagsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe,
            versionId: body.versionId
          }))

          resolve()
        } else {
          const error = err || 'Tag subscription not provided'
          this.context.commit('setTagsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async tagGroupsSubscribe (body: SocketClientTagGroupsSubscribeBody & { reconnect: boolean }) {
    this.tagGroupsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.tagGroupsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setTagGroupsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect,
      versionId: body.versionId
    }))

    const initialValue: TagGroup[] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('tag-groups-subscribe', {
        landscapeId: body.landscapeId,
        versionId: body.versionId
      }, (err, reply) => {
        if (reply) {
          const tagGroupInitialValueListener: ServerEvents['tag-group-initial-value'] = ({ tagGroups, finalValue, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...tagGroups)

              if (finalValue) {
                this.context.commit('setTagGroups', initialValue)

                this.context.commit('setTagGroupsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe,
                  versionId: body.versionId
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const tagGroupAddedListener: ServerEvents['tag-group-added'] = ({ tagGroup, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setTagGroupVersion', tagGroup)
            }
            if (tagGroup.createdById && tagGroup.createdById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const tagGroupModifiedListener: ServerEvents['tag-group-modified'] = ({ tagGroup, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setTagGroupVersion', tagGroup)
            }
            if (tagGroup.updatedById && tagGroup.updatedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const tagGroupRemovedListener: ServerEvents['tag-group-removed'] = ({ tagGroup, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeTagGroup', tagGroup)
            }
            if (tagGroup.deletedById && tagGroup.deletedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('tag-group-initial-value', tagGroupInitialValueListener)
            this.socket.off('tag-group-added', tagGroupAddedListener)
            this.socket.off('tag-group-modified', tagGroupModifiedListener)
            this.socket.off('tag-group-removed', tagGroupRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('tag-groups-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('tagGroups unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('tag-group-initial-value', tagGroupInitialValueListener)
          this.socket.on('tag-group-added', tagGroupAddedListener)
          this.socket.on('tag-group-modified', tagGroupModifiedListener)
          this.socket.on('tag-group-removed', tagGroupRemovedListener)

          this.context.commit('setTagGroupsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe,
            versionId: body.versionId
          }))

          resolve()
        } else {
          const error = err || 'Tag group subscription not provided'
          this.context.commit('setTagGroupsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async tagsList ({ landscapeId, versionId }: { landscapeId: string, versionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { tags } = await TagsService.tagsList(authorization, landscapeId, versionId)
    this.context.commit('setTags', tags)
    return tags
  }

  @Action({ rawError: true })
  async tagCreate ({ landscapeId, versionId, props }: { landscapeId: string, versionId: string, props: TagRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { tag } = await TagsService.tagCreate(authorization, landscapeId, versionId, props)
    this.context.commit('setTagVersion', tag)
    return tag
  }

  @Action({ rawError: true })
  async tagUpsert ({ landscapeId, versionId, tagId, props }: { landscapeId: string, versionId: string, tagId: string, props: TagRequired & TagPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { tag } = await TagsService.tagUpsert(authorization, landscapeId, versionId, tagId, props)
    this.context.commit('setTagVersion', tag)
    return tag
  }

  @Action({ rawError: true })
  async tagUpdate ({ landscapeId, versionId, tagId, props }: { landscapeId: string, versionId: string, tagId: string, props: TagPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { tag } = await TagsService.tagUpdate(authorization, landscapeId, versionId, tagId, props)
    this.context.commit('setTagVersion', tag)
    return tag
  }

  @Action({ rawError: true })
  async tagDelete ({ landscapeId, versionId, tagId }: { landscapeId: string, versionId: string, tagId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commit } = await TagsService.tagDelete(authorization, landscapeId, versionId, tagId)
    this.context.commit('removeTag', { commit, id: tagId })
  }

  @Action({ rawError: true })
  async tagGroupsList ({ landscapeId, versionId }: { landscapeId: string, versionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { tagGroups } = await TagGroupsService.tagGroupsList(authorization, landscapeId, versionId)
    this.context.commit('setTagGroups', tagGroups)
    return tagGroups
  }

  @Action({ rawError: true })
  async tagGroupCreate ({ landscapeId, versionId, props }: { landscapeId: string, versionId: string, props: TagGroupRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { tagGroup } = await TagGroupsService.tagGroupCreate(authorization, landscapeId, versionId, props)
    this.context.commit('setTagGroupVersion', tagGroup)
    return tagGroup
  }

  @Action({ rawError: true })
  async tagGroupUpsert ({ landscapeId, versionId, tagGroupId, props }: { landscapeId: string, versionId: string, tagGroupId: string, props: TagGroupRequired & TagGroupPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { tagGroup } = await TagGroupsService.tagGroupUpsert(authorization, landscapeId, versionId, tagGroupId, props)
    this.context.commit('setTagGroupVersion', tagGroup)
    return tagGroup
  }

  @Action({ rawError: true })
  async tagGroupUpdate ({ landscapeId, versionId, tagGroupId, props }: { landscapeId: string, versionId: string, tagGroupId: string, props: TagPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { tagGroup } = await TagGroupsService.tagGroupUpdate(authorization, landscapeId, versionId, tagGroupId, props)
    this.context.commit('setTagGroupVersion', tagGroup)
    return tagGroup
  }

  @Action({ rawError: true })
  async tagGroupDelete ({ landscapeId, versionId, tagGroupId }: { landscapeId: string, versionId: string, tagGroupId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commit } = await TagGroupsService.tagGroupDelete(authorization, landscapeId, versionId, tagGroupId)
    this.context.commit('removeTagGroup', { commit, id: tagGroupId })
  }
}

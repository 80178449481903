import * as analytics from '@icepanel/app-analytics'
import { ModelObjectType, TagColor, TagGroupIcon } from '@icepanel/platform-api-client'

export const tagGroupIcon: analytics.Property<TagGroupIcon> = {
  displayName: 'Tag group icon',
  examples: [
    'bug',
    'calendar-check',
    'calendar-times',
    'cloud',
    'cog',
    'database',
    'exclamation-triangle',
    'exclamation-triangle',
    'file',
    'globe',
    'laptop-code',
    'lightbulb',
    'lock'
  ],
  name: 'tag_group_icon',
  required: true,
  type: 'string'
}
export const tagGroupName: analytics.Property<string> = {
  displayName: 'Tag group name',
  name: 'tag_group_name',
  required: true,
  type: 'string'
}
export const tagGroupNameLength: analytics.Property<number> = {
  displayName: 'Tag group name length',
  name: 'tag_group_name_length',
  required: true,
  type: 'number'
}
export const tagColor: analytics.Property<TagColor> = {
  displayName: 'Tag color',
  examples: ['beaver', 'black', 'blue', 'dark-blue', 'green', 'grey', 'orange', 'pink', 'purple', 'red', 'white', 'yellow'],
  name: 'tag_color',
  required: true,
  type: 'string'
}
export const tagName: analytics.Property<string> = {
  displayName: 'Tag name',
  name: 'tag_name',
  required: true,
  type: 'string'
}
export const tagNameLength: analytics.Property<number> = {
  displayName: 'Tag name length',
  name: 'tag_name_length',
  required: true,
  type: 'number'
}
export const tagObjectType: analytics.Property<ModelObjectType | 'connection'> = {
  displayName: 'Tag object type',
  name: 'tag_object_type',
  required: true,
  type: 'string'
}
export const tagObjectTypes: analytics.Property<(ModelObjectType | 'connection')[]> = {
  displayName: 'Tag object types',
  name: 'tag_object_types',
  required: true,
  type: 'array'
}
export const tagObjectCount: analytics.Property<number> = {
  displayName: 'Tag object count',
  name: 'tag_object_count',
  required: true,
  type: 'number'
}

import * as faCheckSquare from '@fortawesome/free-regular-svg-icons/faCheckSquare'
import * as faMinusSquare from '@fortawesome/free-regular-svg-icons/faMinusSquare'
import * as fasSquare from '@fortawesome/free-regular-svg-icons/faSquare'
import * as fasTimesCircle from '@fortawesome/free-regular-svg-icons/faTimesCircle'
import * as fasEllipsisH from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import * as fasMapPin from '@fortawesome/free-solid-svg-icons/faMapPin'
import * as fasUndo from '@fortawesome/free-solid-svg-icons/faUndo'
import { TagGroup } from '@icepanel/platform-api-client'

export default [
  faCheckSquare.definition,
  faMinusSquare.definition,
  fasEllipsisH.definition,
  fasMapPin.definition,
  fasSquare.definition,
  fasTimesCircle.definition,
  fasUndo.definition
] as const

export const tagGroups: Record<TagGroup['icon'], string> = {
  bug: '$fas-bug',
  'calendar-check': '$fas-calendar-check',
  'calendar-times': '$fas-calendar-times',
  cloud: '$fas-cloud',
  cog: '$fas-cog',
  database: '$fas-database',
  'exclamation-triangle': '$fas-exclamation-triangle',
  file: '$fas-file-alt',
  globe: '$fas-globe',
  'laptop-code': '$fas-laptop-code',
  lightbulb: '$fas-lightbulb',
  lock: '$custom-solid-lock-alt',
  microchip: '$fas-microchip',
  minus: '$fas-minus',
  mobile: '$fas-mobile-alt',
  'network-wired': '$fas-network-wired',
  plus: '$fas-plus',
  poo: '$fas-poo',
  robot: '$fas-robot',
  rocket: '$fas-rocket',
  'sack-dollar': '$custom-solid-sack-dollar',
  server: '$fas-server',
  sledding: '$custom-solid-sledding',
  snowman: '$fas-snowman',
  star: '$fas-star',
  times: '$fas-times',
  toolbox: '$fas-toolbox',
  trash: '$fas-trash-alt',
  users: '$fas-users',
  wifi: '$fas-wifi'
} as const
